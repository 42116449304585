<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div class="minTitle">藏品现状</div>
        <el-form size="small" ref="inputForm" :model="inputForm" :rules="rules" label-width="100px">
            <el-row :gutter="20">
                <el-col :span="11">
                    <el-form-item label="完残程度" prop="integrity">
                        <el-select v-model="inputForm.integrity"
                                   :disabled="true"
                                   placeholder="请选择完残程度"
                                   style="width: 100%"
                                   clearable>
                            <el-option
                                v-for="item in integrityList"
                                :key="item.id"
                                :label="item.levelName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="完残情况" prop="integrityInfo">
                        <el-input
                            v-model.trim="inputForm.integrityInfo"
                            placeholder="请输入完残情况(限50字)"
                            maxlength="50"
                            :disabled="true"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="22">
                    <el-form-item label="保护效果评估" prop="protectionEffect">
                        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                                  v-model.trim="inputForm.protectionEffect" maxlength="200"
                                  placeholder="请输入对文物保护效果进行评估，如是否有效防止了文物损坏、是否提高了文物的保存状态等(限200字)"
                                  clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="22">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                                  v-model.trim="inputForm.remark" maxlength="200"
                                  placeholder="请输入备注(限200字)"
                                  clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="text_center">
            <el-button size="small" type="primary" @click="sureData()" v-no-more-click>确定</el-button>
            <el-button size="small" @click="handleClose()">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "protectionResults",
    data() {
        return {
            title: '保护结果',
            dialogVisible: false,
            dataId: '',
            repairId: '',
            inputForm: {
                integrity:'',
                integrityInfo:'',
                protectionEffect:'',
                remark:'',
            },
            integrityList: [
                {
                    id: '残缺',
                    levelName: '残缺',
                },
                {
                    id: '基本完整',
                    levelName: '基本完整',
                },
                {
                    id: '完整',
                    levelName: '完整',
                },
                {
                    id: '严重残缺',
                    levelName: '严重残缺',
                },
            ],
            rules: {},
            inputType: false,
            seletData:{},
            protectionId:'',
        }
    },

    methods: {
        init(row,protectionId) {
            console.log(row)
            console.log(protectionId)
            if (row) {
                this.protectionId = protectionId
                this.seletData = row
                this.inputForm.integrity = row.integrity
                this.inputForm.integrityInfo = row.integrityInfo
            }
            this.$axios(this.api.collection.collectionProtectionLinkGetById, {
                id:this.seletData.id,
                protectionId:this.protectionId,
            }, 'get').then(data => {
                if (data.status && data.data) {
                    this.inputForm.protectionEffect = data.data.protectionEffect
                    this.inputForm.remark = data.data.remark
                }
            })
            this.dialogVisible = true
        },

        sureData() {
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    this.$axios(this.api.collection.collectionProtectionLinkUpdateById, {
                        ...this.inputForm,
                        saveFlag:1,
                        collectionId:this.seletData.id,
                        protectionId:this.protectionId,
                        id:this.seletData.collectionProtectionLinkId,
                    }, 'put').then(data => {
                        if (data.status) {
                            this.$message.success('保存成功')
                            this.$emit('sureDa')
                            this.handleClose()
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                }
            })
        },

        handleClose() {
            this.$refs.inputForm.resetFields()
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>
.minTitle {
    font-weight: bold;
    font-size: 14px;
}
</style>